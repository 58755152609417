import { useRef, useState, useEffect, useContext } from "react";
import { Stack } from "@fluentui/react";
import { DismissRegular, SquareRegular, ShieldLockRegular } from "@fluentui/react-icons";

import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";

import styles from "./ChatWidget.module.css";
import Kust_icon from "../../assets/icon_kust.svg";


import {
    conversationApi,
    getUserInfo,
    fetchConversationApi,
    createTicketSession,
    getConversationMessages,
} from "../../api/widget.api";
import { Answer } from "./components/Answer/Answer";
import { QuestionInput } from "./components/QuestionInput";
import { SESSION_ID } from "../../services/temp-session.service";
import { useCookies } from "react-cookie";
import React from "react";
import EventContext from "../../EventContext";
import { SessionError } from "../../errors/session.exception";

import Layout from "./pages/layout/Layout";
import { SystemMessageProvider, useSystemMessage } from "../SystemMessage/SystemMessageContext";
import { ChatMessage, ChatResponse, Citation, ConversationRequest, ConversationSaveRequest, Role, ToolMessageContent } from "../../api";
import { HelpProvider, useHelp } from '../HelpContext/helpContext';
import { ChatWidgetProps } from "../../models/chatWidgetProps";
import { Message } from "../../models/Message";

const ChatWidget: React.FC<ChatWidgetProps> = ({ id }) => {
    // const [token, setToken] = useState('');
    //

    const { closeEvent, eventTriggered, triggerEvent } = useContext(EventContext);

    const { systemMessage, setSystemMessage } = useSystemMessage();
    const { helpMode, setHelpDisabled, setHelpShowed } = useHelp();


    // useEffect(() => {
    //     if (eventTriggered) {
    //         setIsHelpMode(true);
    //         startIntervalConversation();
    //     }
    // }, [eventTriggered]);

    useEffect(() => {
        if (helpMode && helpMode.help) {
            setIsHelpMode(true)
            loadConversation();
            startIntervalConversation();
        }
    }, [helpMode]);

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const [isPageReady, setIsPageReady] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<boolean>(false);
    const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
    const [activeCitation, setActiveCitation] = useState<[content: string, id: string, title: string, filepath: string, url: string, metadata: string]>();
    const [isCitationPanelOpen, setIsCitationPanelOpen] = useState<boolean>(false);
    const [answers, setAnswers] = useState<ChatMessage[]>([]);
    const abortFuncs = useRef([] as AbortController[]);
    const [showAuthMessage, setShowAuthMessage] = useState<boolean>(true);
    const [isHelpMode, setIsHelpMode] = useState<boolean>(false);
    const [isSupportRole, setIsSupportRole] = useState<boolean>(false);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const toggleChat = () => setIsChatOpen(!isChatOpen);
    const [webId, setWebId] = useState<string | undefined>(undefined);
    let currentToken = useRef<string | undefined>();

    const intervalIdRef = useRef<any>(null);
    const checkSessionError = async (exception: any) => {
        if (exception instanceof SessionError) {
            closeSession();
        }
    }
    const startIntervalConversation = async () => {
        if (!intervalIdRef.current) {
            loadConversation();
            const id = setInterval(loadConversation, 20 * 1000); 
            intervalIdRef.current = id;
        }
    };
    const cancelIntervalConversation = () => {
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        }
    };

    // const onLoadSession = () => {
    //     console.log("on storage change")

    //     const helpMode = sessionStorage.getItem('helpMode');

    //     console.log(helpMode)
    //     if (helpMode) {
    //         console.log(JSON.parse(helpMode).role == "support")
    //         loadConversation();
    //         console.log(isSupportRole)
    //         startIntervalConversation();
    //     }
    // };

    const closeSession = () => {
        currentToken.current = undefined
        sessionStorage.clear();
        cancelIntervalConversation();
        closeEvent();
    }
    const loadConversation = async () => {
        if (showLoadingMessage || isLoading) return
        try {
            // setIsLoading(true);
            const abortController = new AbortController();
            abortFuncs.current.unshift(abortController);
            if (!currentToken.current || currentToken.current != "") {
                currentToken.current = sessionStorage.getItem('accessToken') as string
            }

            const { messages, ticket_status, helped } = await getConversationMessages(abortController.signal, currentToken.current && currentToken.current != "" ? currentToken.current : sessionStorage.getItem('accessToken') as string);
            if (ticket_status === 'closed') {
                closeSession();
                return
            }
            if (!helped)
                return

            setIsLoading(true);
            triggerEvent()
            setHelpDisabled(true)
            sessionStorage.setItem('helpMode', JSON.stringify({ help: true, role: 'user' }));
            if (showLoadingMessage) return
            if (messages?.length !== 0) {
                setAnswers(messages);
                if (messages[messages?.length - 1].role == "user")
                    lastQuestionRef.current = messages[messages?.length - 1].content;

                const supportMessageExists = messages.some((message: Message) => message.role === 'support');
                if (supportMessageExists) {
                    setSystemMessage('');
                }
            }
            const helpMode = sessionStorage.getItem('helpMode');
            if (helpMode) {
                const helped = JSON.parse(helpMode).help;
                setIsHelpMode(helped);
                setIsSupportRole(JSON.parse(helpMode).role === 'support');
            }
        } catch (e) {
            checkSessionError(e);
            console.log(e)
        } finally {
            setIsLoading(false);
        }
    };

    const checkSession = async () => {
        try {
            const sessionToken = sessionStorage.getItem('accessToken') as string
            if (sessionToken) {
                currentToken.current = sessionToken
                return
            }
            if (!webId)
                return

            const helpMode = sessionStorage?.getItem('helpMode');
            const helpObj: any = helpMode ? JSON.parse(helpMode) : null

            // if (!helpMode || helpObj?.role == 'user') {
            //     setIsHelpMode(false)
            //     sessionStorage.clear();
            // }
            // closeEvent();
            const tokenRes = await validateId(webId!);
            if (!tokenRes)
                return closeSession()
            currentToken.current = tokenRes
            sessionStorage.setItem('accessToken', tokenRes);
            setHelpShowed(true);
            startIntervalConversation();
            return tokenRes
        } catch (e) {
            console.log(e)
        }
    }

    const sendQuestionRequest = async (question: string) => {
        const tokenRes = await checkSession()
        if (tokenRes && tokenRes !== "")
            currentToken.current = tokenRes


        if (!isHelpMode) {
            return makeGPTApiRequest(question)
        }
        return sendSupportRequest(question)
    }
    const sendSupportRequest = async (question: string) => {
        lastQuestionRef.current = question;
        setIsLoading(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);
        try {
            const userMessage: ChatMessage = {
                role: isSupportRole ? Role.SUPPORT : Role.USER,
                content: question
            };

            let tempArr = [...answers, userMessage]
            setAnswers(tempArr);
            const requestSave: ConversationSaveRequest = {
                message_id: tempArr.length > 0 ? tempArr.length - 2 : 0,
                message: userMessage.content,
                session_id: SESSION_ID.toString(),
                role: isSupportRole ? Role.SUPPORT : Role.USER
            };
            if (!currentToken.current || currentToken.current != "") {
                currentToken.current = sessionStorage.getItem('accessToken') as string
            }

            const response = await fetchConversationApi(requestSave, abortController.signal, currentToken.current && currentToken.current !== "" ? currentToken.current : sessionStorage.getItem('accessToken') as string);
            let errorMessage = "";

            if (response.status == 401 || response.status == 404) {
                errorMessage = "Unauthorized"
                let tempArr = [...answers, userMessage, {
                    role: "error",
                    content: errorMessage
                }]
                setError(true)
                setHelpDisabled(true)
                setAnswers(tempArr);
                return closeSession()
            }
            else if (response.status == 403) {
                errorMessage = "Forbidden: Access is denied from this domain."
                let tempArr = [...answers, userMessage, {
                    role: "error",
                    content: errorMessage
                }]
                setError(true)
                setHelpShowed(false)
                setAnswers(tempArr);
                closeSession()
                return;
            }

            chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" })
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);

        } catch (e) {
            checkSessionError(e)
        } finally {
            setIsLoading(false);
            return abortController.abort();
        }
    }
    const makeGPTApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        setIsLoading(true);
        setShowLoadingMessage(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        const userMessage: ChatMessage = {
            role: "user",
            content: question
        };
        const request: ConversationRequest = {
            messages: [...answers.filter((answer) => answer.role !== "error"), userMessage]
        };

        let result = {} as ChatResponse;

        try {
            let errorMessage = "";

            if (!currentToken.current || currentToken.current != "") {
                currentToken.current = sessionStorage.getItem('accessToken') as string
            }

            const response = await conversationApi(request, abortController.signal, currentToken.current && currentToken.current !== "" ? currentToken.current : sessionStorage.getItem('accessToken') as string);
            if (response && response.status === 503) {
                const errorMessage = "Sorry, server is overloaded, please try again later.";
                const tempArr = [
                    ...answers,
                    userMessage,
                    { role: "error", content: errorMessage }
                ];
                setAnswers(tempArr);
                setShowLoadingMessage(false);
                return;
            }
            if (response.status == 401 || response.status == 404) {
                errorMessage = "Unauthorized"
                let tempArr = [...answers, userMessage, {
                    role: "error",
                    content: errorMessage
                }]
                setError(true)
                setHelpDisabled(true)
                setAnswers(tempArr);
                return closeSession()
            }
            else if (response.status == 403) {
                errorMessage = "Forbidden: Access is denied from this domain."
                let tempArr = [...answers, userMessage, {
                    role: "error",
                    content: errorMessage
                }]
                setError(true)
                setHelpDisabled(true)
                setAnswers(tempArr);
                return closeSession()
            }
            if (response?.body) {

                const reader = response.body.getReader();
                let runningText = "";
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;

                    var text = new TextDecoder("utf-8").decode(value);
                    // const objects = text.split("\n");
                    // objects.forEach((obj) => {
                    //     try {
                    //         runningText += obj+"\n";
                    //         setAnswers([...answers, userMessage, ...[{role:"assistant", content: runningText}]]);
                    //         // runningText = "";
                    //         setShowLoadingMessage(false);
                    //     }
                    //     catch { }
                    // });
                    runningText += text;
                    setAnswers([...answers, userMessage, ...[{ role: "assistant", content: runningText }]]);
                    setShowLoadingMessage(false);
                }
                const result = { role: "assistant", content: runningText }
                let tempArr = [...answers, userMessage, ...[result]]
                runningText = "";
                const requestSave: ConversationSaveRequest = createRequestSave(tempArr, userMessage);
                const requestSaveAnswer: ConversationSaveRequest = createAnswerSave(tempArr, result);
                await fetchConversationApi(requestSave, abortController.signal, currentToken.current);
                await fetchConversationApi(requestSaveAnswer, abortController.signal, currentToken.current);
                setAnswers(tempArr);
                chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" })
            }

        } catch (e) {
            checkSessionError(e)
            if (!abortController.signal.aborted) {
                console.error(result);
                let errorMessage = "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
                if (result.error?.message) {
                    errorMessage = result.error.message;
                }
                else if (typeof result.error === "string") {
                    errorMessage = result.error;
                }
                let tempArr = [...answers, userMessage, {
                    role: "error",
                    content: errorMessage
                }]
                setAnswers(tempArr);
                const requestSave: ConversationSaveRequest = {
                    message_id: tempArr.length > 0 ? tempArr.length - 2 : 0,
                    message: userMessage.content,
                    session_id: SESSION_ID.toString(),
                    role: Role.USER
                };
                await fetchConversationApi(requestSave, abortController.signal, currentToken.current!)

                const requestSaveAnswer: ConversationSaveRequest = {
                    message_id: tempArr.length > 0 ? tempArr.length - 1 : 0,
                    message: errorMessage,
                    session_id: SESSION_ID.toString(),
                    role: Role.ERROR
                };
                await fetchConversationApi(requestSaveAnswer, abortController.signal, currentToken.current!);

            } else {
                let tempArr = [...answers, userMessage]
                setAnswers(tempArr);
                const requestSave: ConversationSaveRequest = {
                    message_id: tempArr.length > 0 ? tempArr.length - 1 : 0,
                    message: userMessage.content,
                    session_id: SESSION_ID.toString(),
                    role: Role.USER
                };
                await fetchConversationApi(requestSave, abortController.signal, currentToken.current!);
            }
        } finally {
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
        }

        return abortController.abort();
    };
    const createRequestSave = (convArr: any, userMessage: any): ConversationSaveRequest => {
        const request: ConversationSaveRequest = {
            message_id: convArr.length > 0 ? convArr.length - 2 : 0,
            message: userMessage.content,
            // TODO delete session_id, in the API replace for the webticket_id
            session_id: SESSION_ID.toString(),
            role: Role.USER
        };
        return request
    }
    const createAnswerSave = (convArr: any[], result: ChatMessage): ConversationSaveRequest => {
        const request: ConversationSaveRequest = {
            message_id: convArr.length > 0 ? convArr.length - 1 : 0,
            message: result.content,
            session_id: SESSION_ID.toString(),
            role: Role.ASSISTANT
        };
        return request
    }

    const stopGenerating = () => {
        abortFuncs.current.forEach(a => a.abort());
        setShowLoadingMessage(false);
        setIsLoading(false);
    }

    useEffect(() => {
        currentToken.current = ""
        sessionStorage.clear();
        setShowAuthMessage(false);
        // getUserInfoList();
        // fetchData();
        // loadConversation();
        // startIntervalConversation();
    }, []);


    useEffect(() => {
        setIsPageReady(false)

        // setToken('');
        // getUserInfoList();
        // sessionStorage.clear();
        const fetchData = async () => {
            try {
                if (!id) {
                    console.error("Error")
                } else {

                    // if (currentToken.current && currentToken.current != "") {
                    //     setIsPageReady(true)
                    //     return currentToken.current
                    // }
                    // const validatedToken = await validateId(id);
                    // if (validatedToken) {
                    //     setToken(validatedToken)
                    //     sessionStorage.setItem('accessToken', validatedToken);
                    // }
                    setWebId(id!)
                    setIsPageReady(!!id)
                    setIsHelpMode(false)
                }
            } catch (error) {
                checkSessionError(error)
                console.error("Error validating ID:", error);
            }
        };
        fetchData();
    }, [id]);

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [showLoadingMessage]);

    const onShowCitation = (citation: Citation) => {
        setActiveCitation([citation.content, citation.id, citation.title ?? "", citation.filepath ?? "", "", ""]);
        setIsCitationPanelOpen(true);
    };

    const parseCitationFromMessage = (message: ChatMessage) => {
        if (message.role === "tool") {
            try {
                const toolMessage = JSON.parse(message.content) as ToolMessageContent;
                return toolMessage.citations;
            }
            catch {
                return [];
            }
        }
        return [];
    }
    const validateId = async (webId?: string): Promise<string | undefined> => {
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);
        if (webId) {
            try {
                const createWebTicket = await createTicketSession({ web_id: webId }, abortController.signal)
                return createWebTicket.token
            } catch (e) {
                checkSessionError(e)
            } finally {
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            }
        }
    }


    return isPageReady ? (
        <Layout>
            <HelpProvider>
                <div className={styles.rootContainer} role="root">
                    {!isChatOpen && (
                        <div className={styles.container} role="main">
                            {showAuthMessage ? (
                                <Stack className={styles.chatEmptyState} >
                                    <ShieldLockRegular className={styles.chatIcon} style={{ color: 'darkorange', height: "200px", width: "200px" }} />
                                    <h1 className={styles.chatEmptyStateTitle}>Authentication Not Configured</h1>
                                    <h2 className={styles.chatEmptyStateSubtitle}>
                                        This app does not have authentication configured. Please add an identity provider by finding your app in the
                                        <a href="https://portal.azure.com/" target="_blank"> Azure Portal </a>
                                        and following
                                        <a href="https://learn.microsoft.com/en-us/azure/app-service/scenario-secure-app-authentication-app-service#3-configure-authentication-and-authorization" target="_blank"> these instructions</a>.
                                    </h2>
                                    <h2 className={styles.chatEmptyStateSubtitle} style={{ fontSize: "20px" }}><strong>Authentication configuration takes a few minutes to apply. </strong></h2>
                                    <h2 className={styles.chatEmptyStateSubtitle} style={{ fontSize: "20px" }}><strong>If you deployed in the last 10 minutes, please wait and reload the page after 10 minutes.</strong></h2>
                                </Stack>
                            ) : (
                                <Stack horizontal className={styles.chatRoot}>
                                    <div className={styles.chatContainer} >
                                        {!lastQuestionRef.current ? (
                                            <Stack className={styles.chatEmptyState}>
                                               <div className={styles.iconWrapper}>
                                               <img src={Kust_icon} className={styles.chatIcon} aria-hidden="true" />
                                               </div>
                                                <h1 className={styles.chatEmptyStateTitle}>Start chatting</h1>
                                                <h2 className={styles.chatEmptyStateSubtitle}>Make a question to our customer service chatbot</h2>
                                            </Stack>
                                        ) : (
                                            <div className={styles.chatMessageStream} style={{ marginBottom: isLoading && !isHelpMode ? "40px" : "30px", }} role="log">
                                                {

                                                    answers.map((answer, index) => (
                                                        <>
                                                            {answer.role === "user" ? (
                                                                <div className={(isSupportRole ? styles.chatMessageGpt : styles.chatMessageUser)} tabIndex={0}>
                                                                    <div style={isSupportRole ? { background: "#afcbe6" } : {}} className={styles.chatMessageUserMessage}>{answer.content}</div>
                                                                </div>
                                                            ) : answer.role === "support" ? (
                                                                <div className={(!isSupportRole ? styles.chatMessageGpt : styles.chatMessageUser)} tabIndex={0}>
                                                                    <div style={!isSupportRole ? { background: "#f5f5ff" } : {}} className={styles.chatMessageUserMessage}>{answer.content}</div>
                                                                </div>
                                                            ) :
                                                                answer.role === "assistant" ? (
                                                                    <div className={!isSupportRole ? styles.chatMessageGpt : styles.chatMessageUser}>
                                                                        <Answer
                                                                            answer={{
                                                                                answer: answer.content,
                                                                                citations: parseCitationFromMessage(answers[index - 1]),
                                                                            }}
                                                                            answers={answers}
                                                                            pos={index}
                                                                            onCitationClicked={c => onShowCitation(c)}
                                                                        />
                                                                    </div>
                                                                ) : answer.role === "system" || answer.role === "error" ? (
                                                                    <div className={styles.chatMessageGpt}>
                                                                        <Answer
                                                                            answer={{
                                                                                answer: answer.content,
                                                                                citations: parseCitationFromMessage(answers[index - 1]),
                                                                            }}
                                                                            answers={answers}
                                                                            pos={index}
                                                                            onCitationClicked={c => onShowCitation(c)}
                                                                        />
                                                                    </div>
                                                                ) : null}
                                                        </>
                                                    ))}
                                                {showLoadingMessage && (
                                                    <>
                                                        <div className={styles.chatMessageUser} style={{ marginBottom: "30px" }}>
                                                            <div className={styles.chatMessageUserMessage}>{lastQuestionRef.current}</div>
                                                        </div>
                                                        <div className={styles.chatMessageGpt}>
                                                            <Answer
                                                                answer={{
                                                                    answer: "Generating answer...",
                                                                    citations: []
                                                                }}
                                                                answers={[]}
                                                                pos={0}
                                                                onCitationClicked={() => null}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}

                                        <Stack horizontal className={styles.chatInput}>
                                            <QuestionInput
                                                clearOnSend
                                                placeholder="Ask something"
                                                disabled={isLoading || isError}
                                                onSend={question => sendQuestionRequest(question)}
                                            />
                                        </Stack>
                                    </div>
                                    {answers?.length > 0 && isCitationPanelOpen && activeCitation && (
                                        <Stack.Item className={styles.citationPanel} tabIndex={0} role="tabpanel" aria-label="Citations Panel">
                                            <Stack horizontal className={styles.citationPanelHeaderContainer} horizontalAlign="space-between" verticalAlign="center">
                                                <span className={styles.citationPanelHeader}>Citations</span>
                                                <DismissRegular className={styles.citationPanelDismiss} onClick={() => setIsCitationPanelOpen(false)} />
                                            </Stack>
                                            <h5 className={styles.citationPanelTitle} tabIndex={0}>{activeCitation[2]}</h5>
                                            <div tabIndex={0}>
                                                <ReactMarkdown
                                                    linkTarget="_blank"
                                                    className={styles.citationPanelContent}
                                                    children={activeCitation[0]}
                                                    remarkPlugins={[remarkGfm]}
                                                    rehypePlugins={[rehypeRaw]}
                                                />
                                            </div>

                                        </Stack.Item>
                                    )}
                                </Stack>
                            )}

                        </div>
                    )}
                </div>
            </HelpProvider>
        </Layout>
    ) : (<div></div>)
};

export default ChatWidget;

